import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'

export const routes: Routes = [
  {
    path: 'kyc',
    loadChildren: () => import('./kyc/kyc.module').then(m => m.KycModule),
  },
  {
    path: 'minderarig',
    loadChildren: () => import('./kyc-minor/kyc-minor.module').then(m => m.KycMinorModule)
  },
  {
    path: 'foralder',
    loadChildren: () => import('./guardian/guardian.module').then(m => m.GuardianModule)
  },
  {
    path: '',
    redirectTo: 'kyc',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'kyc',
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false, useHash: true,
    scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
