<mat-card class="mat-elevation-z10">
  <mat-card-content>
    <h2>Handläggare</h2>
    <p>
      Du är inloggad som administratör. Du kan nu hjälpa din kund att göra sin kundkännedom. Gör ditt val genom att
      klicka på en knapp nedan.
    </p>
  </mat-card-content>
  <mat-card-actions>
    <div class="button-layout">
      <button mat-raised-button color="primary" [routerLink]="['/kyc']">Myndig</button>
      <button mat-raised-button color="primary" [routerLink]="['/minderarig']">Minderårig</button>
      <button mat-stroked-button color="warn" (click)="adminService.reset()">Logga ut</button>
    </div>

  </mat-card-actions>
</mat-card>
