import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HeaderComponent} from './header/header.component'
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component'
import {CustomMaterialsModule} from '../custom-materials-module'
import {CommonRoutingModule} from './common-routing.module'
import {FormsModule} from '@angular/forms'
import {StepperScrollDirective} from './directives/stepper-scroll/stepper-scroll.directive'
import {RadioScrollDirective} from './directives/radio-scroll/radio-scroll.directive'
import {PersonnummerValidator} from './directives/personnummer/personnummer-validator.directive'
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog'
import { SupervisorComponent } from './supervisor/supervisor.component'
import {MatCardModule} from '@angular/material/card'
import {MatButtonModule} from '@angular/material/button'


@NgModule({
  declarations: [
    HeaderComponent,
    BreadcrumbsComponent,
    StepperScrollDirective,
    RadioScrollDirective,
    PersonnummerValidator,
    SupervisorComponent
  ],
  imports: [
    CommonModule,
    CommonRoutingModule,
    CustomMaterialsModule,
    FormsModule,
    MatCardModule,
    MatButtonModule
  ],
    exports: [
        HeaderComponent,
        BreadcrumbsComponent,
        StepperScrollDirective,
        RadioScrollDirective,
        PersonnummerValidator,
        SupervisorComponent
    ],
  providers: [
    PersonnummerValidator,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {width: '90%', maxWidth: '400px'}}
  ]
})
export class SpbCommonModule {
}
