import {Directive} from '@angular/core'
import {AbstractControl, FormControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms'


export const checkboxValidator: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
  const check = Object.keys(group.value).find(key => group.value[key] === true)
  if (!check) {
    return {
      error: {
        message: 'Du måste ange ett syfte, hitta på ett, det spelar inte så stor roll.'
      }
    }
  }
  return null
}


@Directive({
  selector: '[spbCheckboxValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: CheckboxValidator, multi: true}]
})
export class CheckboxValidator implements Validator {

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return checkboxValidator(control)
  }
}
