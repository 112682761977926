import {Directive, HostListener} from '@angular/core'
import {MatRadioChange} from '@angular/material/radio'

@Directive({
  selector: '[spbRadioScroll]'
})
export class RadioScrollDirective {

  constructor() {
  }

  @HostListener('change', ['$event'])
  change(event: MatRadioChange) {
    this.scrollElement(event.source.id)
  }

  private scrollElement(elemId: string): void {
    const stepElement: HTMLElement = document.getElementById(elemId)
    setTimeout(() => {
      stepElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }, 250)
  }

}
