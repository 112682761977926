import {Directive} from '@angular/core'
import {AbstractControl, FormControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms'


export const careOfValidator: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
  if(group.value.careOf === '2'){
    return group.value.coAddress.length > 4 ? null : {
      error: {
        message: 'Du måste ange en care of adress.'
      }
    }
  }
  return null
}


@Directive({
  selector: '[spbCheckboxValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: CareOfValidator, multi: true}]
})
export class CareOfValidator implements Validator {

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return careOfValidator(control)
  }
}
