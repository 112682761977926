import {Component} from '@angular/core'
import {AdminService} from '../../services/admin.service'

@Component({
  selector: 'spb-supervisor',
  templateUrl: './supervisor.component.html',
  styleUrls: ['./supervisor.component.scss']
})
export class SupervisorComponent {

  constructor(public adminService: AdminService) {
  }
}
